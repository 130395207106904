import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { transparentize } from 'polished';
import {
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  ListItemSecondaryAction,
  ListSubheader,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FiExternalLink as ExternalIcon,
  FiMapPin as AddressIcon,
  FiHome as HomeIcon,
  FiClock as ClockIcon,
  FiPhoneCall as PhoneIcon,
  FiMail as EmailIcon,
  FiGlobe as WebIcon,
  FiMessageCircle as MessageIcon,
} from 'react-icons/fi';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaViber as ViberIcon } from 'react-icons/fa';
import Section from '../components/Section';
import Layout from '../layout';
import Seo from '../components/Seo';

const Result = ({ data }) => {
  const theme = useTheme();
  const { strapiZahtjevPrimljen: content } = data;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.clear();
    }
  }, []);

  return (
    <Layout>
      <Section>
        <Grid direction="column" justifyContent="flex-start" alignItems="center" container>
          <Seo title="Zahtjev podnesen" />
          <Box bgcolor={transparentize(0.875, theme.palette.success.main)} borderRadius="4px" width="100%" p={5} mb={6}>
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item sm={2}>
                <Box textAlign="center">
                  <AiOutlineFileDone size={72} color={theme.palette.success.main} />
                </Box>
              </Grid>
              <Grid item sm={10}>
                <Typography variant="h4">{content.naslov}</Typography>
                <Box mt={1}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {content.podnaslov}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container item spacing={2} justifyContent="space-between">
            <Grid md={12} xs={12} item>
              <Box border="1px solid #efefef" display="flex" flexDirection="column" p={5} mb={4}>
                <Typography variant="h5" gutterBottom>
                  {content.rezultatLijeviBox.naslov}
                </Typography>
                {content.rezultatLijeviBox.accountManager.vidljiv && (
                  <Box display="flex" alignItems="center" mb={1} mt={2}>
                    <AvatarOutline>
                      <Avatar src={content.rezultatLijeviBox.accountManager.slika.localFile.publicURL} />
                    </AvatarOutline>
                    <Box ml={2}>
                      <Typography variant="h4">{content.rezultatLijeviBox.accountManager.naslov}</Typography>
                      <Typography variant="subtitle1">{content.rezultatLijeviBox.accountManager.podnaslov}</Typography>
                    </Box>
                  </Box>
                )}
                <List
                  dense
                  disablePadding
                  subheader={<ListSubheader disableSticky>{content.rezultatLijeviBox.podnaslov}</ListSubheader>}
                >
                  {content.rezultatLijeviBox.kontakti.map(({ naslov, podnaslov, tip, url }) => (
                    <StyledListItem key={naslov}>
                      <ListItemAvatar>
                        <Avatar variant="rounded">
                          {tip === 'kancelarija' && <HomeIcon />}
                          {tip === 'radnoVrijeme' && <ClockIcon />}
                          {tip === 'chat' && <MessageIcon />}
                          {tip === 'adresa' && <AddressIcon />}
                          {tip === 'telefon' && <PhoneIcon />}
                          {tip === 'email' && <EmailIcon />}
                          {tip === 'website' && <WebIcon />}
                          {tip === 'viber' && <ViberIcon />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={naslov} secondary={podnaslov} />
                      {url && (
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="phone"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="large"
                          >
                            <ExternalIcon size={16} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </StyledListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid md={12} xs={12} item>
              <Box border="1px solid #efefef" display="flex" flexDirection="column" p={5}>
                <Typography variant="h5" gutterBottom>
                  {content.rezultatDesniBox.naslov}
                </Typography>
                <Stepper nonLinear orientation="vertical">
                  {content.rezultatDesniBox.koraci.map(({ naslov, opis }) => (
                    <Step active key={naslov}>
                      <StepLabel>{naslov}</StepLabel>
                      <StepContent>
                        <Typography variant="subtitle2" gutterBottom>
                          {opis}
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  );
};

const AvatarOutline = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${theme.palette.info.main};
  border-radius: 50%;
  height: 64px;
  width: 64px;

  .MuiAvatar-root {
    height: 60px;
    width: 60px;
  }
  `}
`;

const StyledListItem = styled(ListItem)`
  ${({ theme }) => `
  margin-left: 16px;
  padding-left: 0;
  .MuiAvatar-root {
    background-color: ${transparentize(0.875, theme.palette.secondary.main)};
    color: ${theme.palette.secondary.main};
  }
  `}
`;

export const query = graphql`
query {
  strapiZahtjevPrimljen {
    naslov
    podnaslov
    rezultatLijeviBox {
      naslov
      podnaslov
      accountManager {
        naslov
        podnaslov
        vidljiv
        slika {
          localFile {
            publicURL
          }
        }
      }
      kontakti {
        naslov
        podnaslov
        tip
        url
      }
    }
    rezultatDesniBox {
      naslov
      koraci {
        naslov
        opis
      }
    }
  }
}
`;

export default Result;
